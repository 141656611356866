/* eslint-disable import/no-named-as-default */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import {
  Window,
  WindowContent,
  WindowHeader,
  Button,
  Tabs,
  Tab,
  TabBody,
} from 'react95';
import WindowContext from '../../contexts/window-context';
import AboutContent from './AboutByteSlice';
import ContactInfo from './ContactInfo';

const WindowWrapper = styled.div`
  padding: 5rem 1rem;
  background: teal;

  @media (min-width: 1024px) {
    padding: 5rem;
  }

  .window-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -1px;
    margin-top: -1px;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      background: ___CSS_0___;
    }
    &:before {
      height: 100%;
      width: 3px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 3px;
      width: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .window:nth-child(2) {
    margin: 2rem;
  }
`;

const StyledWindow = styled(Window)`
  min-height: 200px;
  top: 50%;
  font-size: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    left: 50%;
    width: 500px;
  }
`;

export const ByteSliceInfo = () => {
  const [openLabel, setOpenLabel] = useState(0);
  const { aboutByteSliceIsOpen, toggleByteSlice } = useContext(WindowContext);

  return (
    <div>
      {aboutByteSliceIsOpen && (
        <WindowWrapper>
          <StyledWindow resizable>
            <WindowHeader className="window-header">
              <span>About YACC</span>
              <Button onClick={toggleByteSlice}>
                <span className="close-icon">X</span>
              </Button>
            </WindowHeader>
            <WindowContent>
              <Tabs
                value={openLabel}
                onChange={(e, value) => setOpenLabel(value)}
              >
                <Tab value={0}>About us</Tab>
                <Tab value={1}>Contact</Tab>
              </Tabs>
              <TabBody>
                {openLabel === 0 && <AboutContent />}
                {openLabel === 1 && <ContactInfo />}
              </TabBody>
            </WindowContent>
          </StyledWindow>
        </WindowWrapper>
      )}
    </div>
  );
};

export default ByteSliceInfo;
