/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';

const AboutContent = () => (
  <div>
    <p>
      YACC is Yet Another Consulting Company that is not just another consulting
      company. This company was founded at the end of 2019 and has today grown
      to become 10 employees, all of whom are partners.
    </p>
    <p>
      <br />
      The most important thing for us is that all our consultants have fun and
      challenging assignments. A selection of customers that we've worked with
      since we started are: Kronans Apotek, Embark, Spotify, Discovery, Babyshop
      Group, Hi3G, Detectify and Zettle by Paypal.
    </p>
    <br />
    <p>
      Since all partners work as consultants in the company, it has always been
      of the utmost importance that the company can offer the best benefit
      package there is. No other consulting company can compete with us
      regarding this, which of course is something we're incredibly proud of.
    </p>
    <br />
    <p>TL;DR: Come and work with us.</p>
  </div>
);

export default AboutContent;
