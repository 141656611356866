import * as React from 'react';
import { ByteSliceInfo } from './about-us/ByteSliceInfo';

export const Content = () => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <ByteSliceInfo />
    {/* <EmployeeInfo style={{ display: 'flex', flexDirection: 'column' }} />
    <CustomerInfo style={{ display: 'flex', flexDirection: 'column' }} /> */}
  </div>
);

export default Content;
