import * as React from 'react';
import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  td,
  th {
    padding-right: 15px;
    padding-bottom: 10px;
    text-align: left;
  }
`;

export const ContactInfo = () => (
  <StyledTableWrapper>
    <table>
      <tbody>
        <tr>
          <td>Visiting address:</td>
          <td>Torsgatan 8A, 111 23 Stockholm</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>
            <a href="mailto:hello@yacc.dev">hello@yacc.dev</a>
          </td>
        </tr>
      </tbody>
    </table>
  </StyledTableWrapper>
);

export default ContactInfo;
