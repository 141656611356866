/* eslint-disable react/prop-types */
import React, { useState, createContext } from 'react';

const WindowContext = createContext({
  aboutByteSliceIsOpen: false,
  aboutEmployeesIsOpen: false,
  aboutCustomersIsOpen: false,
  toggleByteSlice: () => {},
  toggleEmployees: () => {},
  toggleCustomers: () => {},
  closeByteSlice: () => {},
  closeEmployees: () => {},
  closeCustomers: () => {},
});

export const WindowContextProvider = ({ children }) => {
  const [aboutByteSliceIsOpen, setAboutByteSliceIsOpen] = useState(false);
  const [aboutEmployeesIsOpen, setAboutEmployeesIsOpen] = useState(false);
  const [aboutCustomersIsOpen, setAboutCustomersIsOpen] = useState(false);

  const closeByteSlice = () => {
    setAboutByteSliceIsOpen(false);
  };

  const closeEmployees = () => {
    setAboutEmployeesIsOpen(false);
  };

  const closeCustomers = () => {
    setAboutCustomersIsOpen(false);
  };

  const toggleByteSlice = () => {
    setAboutByteSliceIsOpen(!aboutByteSliceIsOpen);
  };

  const toggleEmployees = () => {
    setAboutEmployeesIsOpen(!aboutEmployeesIsOpen);
  };

  const toggleCustomers = () => {
    setAboutCustomersIsOpen(!aboutCustomersIsOpen);
  };

  return (
    <WindowContext.Provider
      value={{
        aboutByteSliceIsOpen,
        aboutEmployeesIsOpen,
        aboutCustomersIsOpen,
        toggleByteSlice,
        toggleEmployees,
        toggleCustomers,
        closeByteSlice,
        closeEmployees,
        closeCustomers,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};
export default WindowContext;
