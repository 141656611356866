import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Button, List, ListItem, Divider } from 'react95';
import logoIMG from '../../assets/images/yacc_start_menu.png';
import WindowContext from '../../contexts/window-context';

export const StartBar = () => {
  const [open, setOpen] = useState(false);
  const ctx = useContext(WindowContext);

  return (
    <div>
      <AppBar>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Button
              onClick={() => setOpen(!open)}
              active={open}
              style={{ fontWeight: 'bold' }}
            >
              <img
                src={logoIMG}
                alt="YACC logo"
                style={{ height: '20px', marginRight: 4 }}
              />
            </Button>
            {open && (
              <List
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '100%',
                }}
                onClick={() => setOpen(false)}
              >
                <ListItem onClick={ctx.toggleByteSlice}>
                  <span role="img" aria-label="📁">
                    📁
                  </span>
                  About YACC
                </ListItem>
                {/* <ListItem onClick={ctx.toggleEmployees}>
                  <span role="img" aria-label="👨‍💻">
                    👨‍💻
                  </span>
                  Employees
                </ListItem>
                <ListItem onClick={ctx.toggleCustomers}>
                  <span role="img" aria-label="👨‍💻">
                    👨‍💻👨‍💻
                  </span>
                  Customers
                </ListItem> */}
                <Divider />
                <ListItem disabled>
                  <span role="img" aria-label="🔙">
                    🔙
                  </span>
                  Logout
                </ListItem>
              </List>
            )}
          </div>
          {/* <TextField placeholder="Search..." width={150} /> */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default StartBar;
